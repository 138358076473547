@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap);
/*

Development:
http://digitalmassive.com

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/

/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 22px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: rgb(51, 255, 187);
}

a:hover {
  color: rgb(22, 194, 99);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 22px;
  line-height: 25px;
  color: #2a2a2a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: rgb(0, 0, 0);
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Rubik';
  color: #ffffff;
  font-weight: 'bold' !important;
  background-color: black !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  /* max-width: 80vh;
  max-height: 80vh; */
  padding-top: 60px;
  padding-left: 230px;
  
  /* margin: 0 auto; */
  text-align: left;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0);
}


button {
  padding: 9px 16px;
  max-height: 40px;
  margin-right: 40px;
  /*box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 20%);*/
  border-color: #25dd96;
  color: #000000;
  background-color: #25dd96;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

button:hover {
  margin-top: -1px;
}

#title {
  padding-top: 32px;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  font-family: 'Orbitron' !important;
  text-align: right;
  float:right;
  margin: 15px;
  font-size: 14px;
  
  /* margin-bottom: 50px; */
  background-color: #25dd96 !important;
  color: black !important;
  line-height: 16px;
  border: 0;
  border-radius: 20px;
}
/*
#walletButton:focus {
  outline:none;
  border: none;
}

#walletButton:hover {
  outline:none;
  border :none;
} */

#mintButton {
  font-family: 'Orbitron';
  background-color: #25dd96;
  color:rgb(0, 0, 0);
  border-radius: 20px;
  margin-left: 20px;
  padding: 20px;
  padding: 9px 16px; 
  max-height: 100px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.cyTopNav {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.cyTopNav a {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.cyTopNavWalletLink {
  background-color: transparent !important;
  color: #000000 !important;
  font-size: 10px;
}

#cyTopNavWalletLink {
  background-color: transparent !important;
  color: #000000 !important;
  font-size: 10px;
}

.button-container-div {
  
    position: fixed;
    left: 50%;
    top: 40%;
 
  /* width: 100%;
  height: 300px;
  border: 1px solid #ff8899;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-bg {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.demo-content {
  position: relative;
}


.cy-header{
  font-family: 'Orbitron';
  font-weight: 900;
  color:#25dd96 !important;
  padding: 20px;
}

.cy-para{
  font-family: 'Orbitron';
  font-weight: 400;
  font-size: 14px;
  color:#ffffff !important;
  padding: 10px;
  width: 50vw;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 0px;
  margin-top: 0;
}

/* For devices with width of 480px and less, like phones */
@media only screen and (max-width: 480px) {
  .Minter {
    padding-top: 20px;
    padding-left: 100px;
  }
} 

/* For devices with width between 481px and 768px, like larger phones and portrait tablets */
@media only screen and (min-width: 481px) {
  .Minter {
    padding-top: 20px;
    padding-left: 100px;
  }
} 

/* For devices with width between 769px and 1279px, like landscape tablets and laptops */
@media only screen and (min-width: 769px) {

} 

/* For devices with width of 1280px and more, like desktop computers */
@media only screen and (min-width: 1280px) {

}



/* ---- stats.js ---- */

.count-particles{
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13E8E9;
  font-size: .8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles{
  font-size: 1.1em;
}

#stats,
.count-particles{
  -webkit-user-select: none;
}

#stats{
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.count-particles{
  border-radius: 0 0 3px 3px;
}



